/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";

/* ICON IMPORTS */
import ExportIcon from "@iconscout/react-unicons/icons/uil-export";
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-trash";

/* API IMPORTS */
import {
  deleteContactUser,
  editContactUser,
  getContactUser,
} from "../../../service/api";
import moment from "moment";
import { AGENT_ID } from "../../../config";

const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (
      string.charAt(i) === string.charAt(i).toUpperCase() &&
      string.charAt(i) !== " "
    ) {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else if (extractedString?.length === 0) {
    return string[0];
  } else {
    return extractedString[0];
  }
};

const options = [
  { value: "CONTACTUS", label: "Contact Us" },
  // { value: "NEWSLETTER", label: "Newsletter" },
  { value: "PROPERTYCONTACTUS", label: "Property Contact Us" }
]

const Users = () => {
  /* MODAL STATES */
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [type, setType] = useState({ value: "CONTACTUS", label: "Contact Us" });
  const [fetchedData, setFetchedData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [editid, setEditid] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const [viewData, setViewData] = useState(null);
  const [viewModal, setViewModal] = useState(false)

  const columns = [
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        row?.name ? <div className="user-detail">
          <div className="avatar">{findUpper(row?.name)}</div>
          <h5>{row?.name}</h5>

        </div> : "-"
      ),
    },
    {
      name: "Phone",
      selector: (row) => (
        <span className={row?.phone ? "light-text" : "light-text"}>
          {row?.phone ? row?.phone : "-"}
        </span>
      ),
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row?.email}</span>,
    },
    {
      name: "Date",
      selector: (row) => (
        <span className="light-text">
          {moment(row?.updatedAt).format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditUserModal(!editUserModal);
              setEditid(row?._id);
              setMessage(row?.message);
              setFirstName(row?.name);
              setEmail(row?.email);
              setPhone(row?.phone);
            }}
          />
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteModal(!deletemodal);
              setEditid(row?._id);
            }}
          />
        </div>
      ),
    },
  ];

  const columnsNotification = [
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row?.email}</span>,
    },
    {
      name: "Date",
      selector: (row) => (
        <span className="light-text">
          {moment(row?.updatedAt).format("MM/DD/YYYY")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          {/* <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditUserModal(!editUserModal);
              setEditid(row?._id);
              setMessage(row?.message);
              setFirstName(row?.name);
              setEmail(row?.email);
              setPhone(row?.phone);
            }}
          /> */}
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteModal(!deletemodal);
              setEditid(row?._id);
            }}
          />
        </div>
      ),
    },
  ];

  // const handleFilter = (event) => {
  //   const newData = filterData.filter((row) =>
  //     row?.name.toLowerCase().includes(event.target.value.toLowerCase())
  //   );
  //   setFetchedData(newData);
  // };

  const handleDelete = () => {
    deleteContactUser(editid)
      .then((res) => {
        getUserData();
        console.log(res);
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deletemodal);
      });
  };

  const handleEdit = () => {
    let formData = new FormData();
    let name = firstName + " " + lastName;
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("type", type?.value);
    formData.append("phone", phone);
    const formDataIterator = formData.entries();
    for (const [key, value] of formDataIterator) {
      console.log(`${key}: ${value}`);
    }
    console.log(editid);
    editContactUser(editid, formData)
      .then((res) => {
        console.log(res);
        toast.success(res?.message)
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setEditUserModal(!editUserModal);
        getUserData();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((res) => {
      });
  };

  const getUserData = () => {
    const params = {
      page: page + 1,
      sizePerPage: sizePerPage,
      type: type?.value,
      sourceAgentId: AGENT_ID
    };
    getContactUser(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        setFilterData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      });
  };

  useEffect(() => {
    getUserData();
  }, [page, sizePerPage, type]);

  return (
    <section className="users">
      <ToastContainer />
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Contacts List</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your contacts and edit their information
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                {/* <Button className="import-btn">
                  <ExportIcon size="14" color="#323232" className="btn-icon" />
                  Export
                </Button> */}
              </div>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <p>Showing <b>{fetchedData?.length}</b> queries</p>
              <div className="right-header">
                <Select
                  options={options}
                  className="react-select mx-3"
                  name="category"
                  placeholder="Select Type"
                  classNamePrefix="action-select"
                  value={type}
                  onChange={(e) => setType(e)}
                />
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={handleFilter}
                /> */}
              </div>
            </Card.Header>

            <Card.Body className="users-list-body">
              {fetchedData?.length === 0 ? (
                <p style={{ textAlign: 'center', fontWeight: '600', marginBottom: '10px', marginTop: '10px' }}>No data found.</p>
              ) : (
                <DataTable
                  columns={type?.value === 'NEWSLETTER' ? columnsNotification : columns}
                  data={fetchedData}
                  pagination
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deletemodal}
        onHide={() => setDeleteModal(!deletemodal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these contact details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setDeleteModal(!deletemodal)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="md"
        show={editUserModal}
        onHide={() => setEditUserModal(!editUserModal)}
      >
        <Modal.Header className="add-user-modal-header">
          <h5>Edit Contact</h5>
        </Modal.Header>
        <Modal.Body>
          <form className="add-user-form">
            <Row>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="fName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Name"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="placeholder@roundtechsquare.com"
                  />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    className="phone-control"
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setEditUserModal(!editUserModal)}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleEdit}>
            Save details
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View MODAL */}
      <Modal
        centered
        backdrop="static"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>{viewData?.type ? viewData?.type === 'NEWSLETTER' ? 'Newsletter' : viewData?.type === "CONTACTUS" ? "Contact Us" : null : null} Details</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">

          {viewData?.name ? <p><strong>Name: </strong>{viewData?.name}</p> : null}
          {viewData?.email ? <p><strong>Email: </strong>{viewData?.email}</p> : null}
          {viewData?.phone ? <p><strong>Phone: </strong>{viewData?.phone}</p> : null}
          {viewData?.message ? <p><strong>Message: </strong>{viewData?.message}</p> : null}
          {/* AGENT DETAILS */}
          {viewData?.agentId ? <h6 className="mt-4">Agent Details</h6> : null}
          {viewData?.agentId ? <p><strong>Agent Name: </strong>{viewData?.agentId?.firstName + " " + viewData?.agentId?.lastName}</p> : null}
          {viewData?.agentId ? <p><strong>Agent Phone: </strong>{viewData?.agentId?.mobile}</p> : null}
          {viewData?.agentId ? <p><strong>Agent Email: </strong>{viewData?.agentId?.email}</p> : null}
          {/* PROPERTY DETAILS */}
          {viewData?.propertyDetails ? <h6 className="mt-4">Property Details</h6> : null}
          {viewData?.propertyDetails ? <p><strong>Property Address: </strong>{viewData?.propertyDetails?.propertyAddress}</p> : null}
          {viewData?.propertyDetails ? <p><strong>Property MLS ID: </strong>{viewData?.propertyDetails?.propertyMLSId}</p> : null}

        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setViewModal(!viewModal)}
          >
            Cancel
          </Button>
          {/* <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Users;
